import styled from "@emotion/styled";
import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { OurExpertsProps, TurretExpert } from "../OurExperts";
import { Grid, Row, Column } from "@ryerson/frontend.layout";
import rgba from "polished/lib/color/rgba";

const CallUs = styled.div`
	margin-top: 50px;
	padding-left: 20px;
	text-align: left;
	width: 60%;
`;
const CallUsSection = styled.div`
	margin-top: 100px;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-top: 1px solid ${rgba(theme.colors.primary.secondaryBrand, 0.1)};
		`;
	}}
`;
const CompanyDetails = styled(Flex)`
	margin-bottom: 101px;
`;
const logo = css`
	mix-blend-mode: exclusion;
	opacity: 1;
	width: 100%;
`;
const OfficeSection = styled.div`
	width: 100%;
	max-height: 360px;
	height: 360px;
	padding: 35px;
	box-sizing: border-box;
	border-radius: 2px;
	flex-grow: 1;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.background};
		`;
	}}
`;
const Profile = styled(Flex)`
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 360px;
`;
const ProfileInfo = styled.div`
	padding: 40px;
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 40px;
`;

type ProfileCardType = {
	profile: TurretExpert;
	index: number;
};

const ProfileCard = (props: ProfileCardType) => {
	const { theme } = useTheme();
	return (
		<Profile
			direction="column"
			justifyContent="flex-end"
			css={css`
				background-image: url(${props.profile.imageUrl});
			`}
		>
			<ProfileInfo>
				<Typography
					variant="p"
					color={theme.colors.primary.white}
					size="sm"
					weight="bold"
					css={{ margin: "0" }}
				>
					{props.profile.name}
				</Typography>
				<Typography
					variant="p"
					color={theme.colors.primary.lightGray}
					size="sm"
					css={{ margin: "0" }}
				>
					{props.profile.title}
				</Typography>
			</ProfileInfo>
		</Profile>
	);
};

const CompanyCard = (props: any) => {
	const { theme } = useTheme();
	return (
		<OfficeSection theme={theme}>
			<CompanyDetails justifyContent="space-between" theme={theme}>
				<FlexItem>
					<Typography
						variant="div"
						color={theme.colors.primary.secondaryBrand}
						weight="bold"
						size="lg"
					>
						{props.info.companyName}
					</Typography>
					<Typography variant="p" size="md">
						{props.info.address1}
						<br /> {props.info.address2}
						<br /> {props.info.phone}
					</Typography>
				</FlexItem>
				<FlexItem>
					<img src={props?.info.logoUrl} css={logo} />
				</FlexItem>
			</CompanyDetails>
			<CallUsSection theme={theme}>
				<Typography variant="div" size="sm" type="secondary" css={{ marginTop: "35px" }}>
					{props.info.questionsLine}{" "}
					<Typography
						variant="span"
						color={theme.colors.primary.secondaryBrand}
						weight="bold"
						size="lg"
					>
						{props.info.questionsPhone}
					</Typography>
				</Typography>
			</CallUsSection>
		</OfficeSection>
	);
};

const OurExpertsDesktop: React.FC<OurExpertsProps> = ({ ...props }) => {
	const { theme } = useTheme();
	return (
		<ContentSection type="secondary" vPadding="100px">
			<Grid>
				<Row>
					<Column lg={6}>
						<Typography
							variant="h1"
							color={theme.colors.primary.secondaryBrand}
							font="inherit"
							weight="bold"
							css={{ whiteSpace: "pre-line" }}
						>
							{props.content.title}
						</Typography>
					</Column>
					<Column lg={6}>
						<CallUs>
							<Typography variant="div" color={theme.colors.primary.gray} size="md">
								{props.content.description}
							</Typography>
						</CallUs>
					</Column>
				</Row>
			</Grid>
			<Grid gutter={0}>
				<Row>
					{props.content.experts.slice(0, 2).map(function (expert: any, index: number) {
						return (
							<Column
								lg={3}
								style={css`
									padding-right: 40px;
									padding-top: 40px;
								`}
								key={index}
							>
								<ProfileCard key={index} index={index} profile={expert} />
							</Column>
						);
					})}
					<Column
						lg={6}
						style={css`
							padding-top: 40px;
						`}
					>
						<CompanyCard info={props.content} />
					</Column>
				</Row>
				<Row>
					{props.content.experts.map(function (expert: any, index: number) {
						return index > 1 ? (
							<Column
								key={index}
								lg={3}
								style={css`
									padding-top: 40px;
									padding-right: ${index === 5 ? "0px" : "40px"};
								`}
							>
								<ProfileCard key={index} index={index} profile={expert} />
							</Column>
						) : (
							<React.Fragment key={index}></React.Fragment>
						);
					})}
				</Row>
			</Grid>
			<Spacer />
		</ContentSection>
	);
};

export default OurExpertsDesktop;
